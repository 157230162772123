<template>
  <div class="cloudServer">
    <img src="../../assets/imges/succedaneum.png" class="headerimg" />

    <div class="appeal">
      <div class="title">数百种实例规格，满足不同场景的业务诉求</div>
      <div class="appealine">
        <div class="appealinetitle">
          <span class="linetitle">实例类型：</span
          ><span class="lineinfo">高性能计算型实例</span>
        </div>
        <div class="appealinetitle">
          <span class="linetitle">应用场景：</span
          ><span class="lineinfo">全部</span>
        </div>
      </div>

      <div class="appealtable">
        <div class="appealinfo">
          <div class="appealtitle">
            <span style="vertical-align: middle">高性能计算型H3</span>
            <span class="tag">热销</span>
          </div>
          <div class="appealtext">
            搭载英特尔® 至强®
            可扩展处理器，配套高性能网络，提供更稳定、高性能的计算能力
          </div>
          <div class="appealtitle">适用场景：</div>
          <div class="appealtlist">
            <div class="text">
              <div class="block"></div>
              高性能前端集群
            </div>
            <div class="text">
              <div class="block"></div>
              Web 服务器
            </div>
            <div class="text">
              <div class="block"></div>
              高性能科学和工程应用
            </div>
            <div class="text">
              <div class="block"></div>
              广告服务
            </div>
            <div class="text">
              <div class="block"></div>
              MMO 游戏
            </div>
            <div class="text">
              <div class="block"></div>
              视频编码和分布式分析
            </div>
          </div>

          <div class="appeallisttwo">
            <div class="appeallisttwocard">
              <div class="appealcardtitle">CPU/内存配比</div>
              <div class="appealcardtext">1:2/1:4</div>
            </div>
            <div class="appeallisttwocard">
              <div class="appealcardtitle">vCPU数量范围</div>
              <div class="appealcardtext">2~32</div>
            </div>
            <div class="appeallisttwocard">
              <div class="appealcardtitle">基频/睿频</div>
              <div class="appealcardtext">3.2GHz/4.2GHz</div>
            </div>
            <div class="appeallisttwocard">
              <div class="appealcardtitle">网络收发包</div>
              <div class="appealcardtext">400万PPS</div>
            </div>
            <div class="appeallisttwocard">
              <div class="appealcardtitle">内网带宽</div>
              <div class="appealcardtext">17 Gbps</div>
            </div>
          </div>
        </div>
        <div class="appealimg">
          <img src="../../assets/product/cloudServer/2.png" />
        </div>
      </div>
    </div>

    <div class="where">
      <div class="title">为什么选择邦伲德弹性云服务器ECS</div>
      <div class="wheretable">
        <div class="wherecard">
          <div>
            <div class="whereinfo">
              <div class="wheretitle">弹性灵活，助您低成本轻松上云</div>
              <div class="wheretext">
                <div class="block"></div>
                <div class="wherediv">
                  用户可通过管理控制台、API、命令行等方式对服务器进行重启、重置密码、重装系统、网络隔离等操作
                </div>
              </div>
              <div class="wheretext">
                <div class="block"></div>
                <div class="wherediv">
                  用户可通过web页面或开放API，同时管理一个、数百个、甚至数千个ECS实例
                </div>
              </div>
              <div class="wheretext">
                <div class="block"></div>
                <div class="wherediv">
                  用户可根据业务需求自行定义弹性伸缩策略，通过可视化控制台配置定时或周期性的监控策略，动态调整弹性云服务器实例，满足业务需求的同时，减少资源投入
                </div>
              </div>
            </div>
            <div class="trademark">
              <img src="../../assets/product/cloudServer/4.png" alt="" />
              <span>
                邦伲德云服务器灵活的计费模式，即租即用，按需购买/释放，VM关机不收费，助力赞奇渲染降本增效
              </span>
            </div>
          </div>
          <div class="whereimg">
            <img src="../../assets/product/cloudServer/3.png" alt="" />
          </div>
        </div>

        <div class="wherecard">
          <div class="whereimg">
            <img src="../../assets/product/cloudServer/5.png" alt="" />
          </div>
          <div>
            <div class="whereinfo">
              <div class="wheretitle">性能强劲，为企业提供旗舰级产品</div>
              <div class="wheretext">
                <div class="block"></div>
                <div class="wherediv">
                  最大提供208vCPU，4096G内存，引领业界顶级算力
                </div>
              </div>
              <div class="wheretext">
                <div class="block"></div>
                <div class="wherediv">
                  业界首推千万级PPS转发实例，单实例最大内网带宽可达44Gbps，最大转发包速率可达1200万PPS
                </div>
              </div>
              <div class="wheretext">
                <div class="block"></div>
                <div class="wherediv">
                  提供按需、包周期、竞价等多种计费模式，满足各类业务使用需求
                </div>
              </div>
            </div>
            <div class="trademark">
              <img src="../../assets/product/cloudServer/6.png" alt="" />
              <span>
                邦伲德ECS极致弹性，千台虚拟机实现分钟级极速发放，帮助新浪新闻应对互联网信息“洪峰”
              </span>
            </div>
          </div>
        </div>

        <div class="wherecard">
          <div>
            <div class="whereinfo">
              <div class="wheretitle">多规格类型，满足不同企业的个性化需求</div>
              <div class="wheretext">
                <div class="block"></div>
                <div class="wherediv">
                  提供X86、鲲鹏、昇腾、异构等多种类型的弹性云服务器
                </div>
              </div>
              <div class="wheretext">
                <div class="block"></div>
                <div class="wherediv">
                  提供公共、私有、共享三种镜像，还可以使用镜像市场提供的包含各类软件的优质第三方镜像
                </div>
              </div>
              <div class="wheretext">
                <div class="block"></div>
                <div class="wherediv">
                  所有云服务器和镜像可一键部署，无需配置，满足用户建站、应用开发、可视化管理等个性化需求
                </div>
              </div>
            </div>
            <div class="trademark">
              <img src="../../assets/product/cloudServer/8.png" alt="" />
              <span>
                分析报告：邦伲德市场排名中国前三、全球前六，增速全球最快
              </span>
            </div>
          </div>
          <div class="whereimg">
            <img src="../../assets/product/cloudServer/7.png" alt="" />
          </div>
        </div>

        <div class="wherecard">
          <div class="whereimg">
            <img src="../../assets/product/cloudServer/9.png" alt="" />
          </div>
          <div>
            <div class="whereinfo">
              <div class="wheretitle">数据可靠，提供坚实的基础数据平台</div>
              <div class="wheretext">
                <div class="block"></div>
                <div class="wherediv">
                  3副本冗余，数据持久性高达99.9999999%，保证数据安全可靠，保护您的业务免受故障影响
                </div>
              </div>
              <div class="wheretext">
                <div class="block"></div>
                <div class="wherediv">
                  单盘达到33000
                  IOPS、350MB/s吞吐量，最低1ms时延，可根据业务需要灵活选择不同性能的云硬盘
                </div>
              </div>
              <div class="wheretext">
                <div class="block"></div>
                <div class="wherediv">
                  支持共享云硬盘、云硬盘加密、云硬盘备份、云服务器备份、快照等功能，可对接满足不同业务场景的需求
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="wherecard">
          <div>
            <div class="whereinfo">
              <div class="wheretitle">多重防护，专业保护您的数据安全</div>
              <div class="wheretext">
                <div class="block"></div>
                <div class="wherediv">
                  帮助用户快速发现安全弱点和威胁，并给出最佳安全实践建议，有效减少或避免由于网络中病毒和恶意攻击带来的损失
                </div>
              </div>
              <div class="wheretext">
                <div class="block"></div>
                <div class="wherediv">
                  通过专业的防DDoS设备为客户互联网应用提供精细化的抵御DDoS攻击能力
                </div>
              </div>
              <div class="wheretext">
                <div class="block"></div>
                <div class="wherediv">
                  联合多家数字证书服务机构，为您提供的一站式安全套接层（SSL）证书和传输层安全（TLS）证书的全生命周期管理服务，实现网站的可信身份认证与安全数据传输
                </div>
              </div>
            </div>
            <div class="trademark">
              <img src="../../assets/product/cloudServer/11.png" alt="" />
              <span> 分析报告：邦伲德位居中国云厂商安全领导者地位 </span>
            </div>
          </div>
          <div class="whereimg">
            <img src="../../assets/product/cloudServer/10.png" alt="" />
          </div>
        </div>

        <div class="wherecard">
          <div class="whereimg">
            <img src="../../assets/product/cloudServer/12.png" alt="" />
          </div>
          <div>
            <div class="whereinfo">
              <div class="wheretitle">模块化集成，打造云生态底座</div>
              <div class="wheretext">
                <div class="block"></div>
                <div class="wherediv">
                  ECS可与各类云服务实现无缝解耦操作，如对象存储、网络、VPC、云数据库等，为用户的各种业务提供完善的解决方案
                </div>
              </div>
              <div class="wheretext">
                <div class="block"></div>
                <div class="wherediv">
                  自研鲲鹏处理器，端云同构，性能提升40%，把优秀的业务体验，超高的性价比提供给客户，为端边云生态融合提供可能，帮助企业开创更多新的产业机遇
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="scene">
      <div class="title">应用场景</div>
      <div class="title2">高性能计算</div>
      <div class="scenetable">
        <div class="sceneimg">
          <img src="../../assets/product/cloudServer/13.png" alt="" />
        </div>
        <div class="scenecard">
          <div class="scenetitle">场景特点</div>
          <div class="scenetext">高计算能力</div>
          <div class="scenetext scenebottpm">高吞吐量</div>

          <div class="scenetitle">适用场景</div>
          <div class="scenetext scenebottpm">
            科学计算 | 基因工程 | 游戏动画 | 生物制药计算和存储系统
          </div>

          <div class="scenetitle">推荐方案</div>
          <div class="scenetext scenebottpm">
            推荐使用高性能计算型弹性云服务器，主要使用在受计算限制的高性能处理器的应用程序上，适合要求提供海量并行计算资源、高性能的基础设施服务，需要达到高性能计算和海量存储，对渲染的效率有一定保障的场景
          </div>

          <div class="scenetitle">推荐配置</div>
          <div class="sceneinfo">
            <div class="scenetitle">高性能计算型 丨h3.8xlarge.4丨32核 128G</div>
            <div class="sceneinfocontent">
              <div class="content">
                <div class="contenttitle">带宽</div>
                <div class="contentnum">10M</div>
              </div>
              <div class="content contentborder">
                <div class="contenttitle">IP</div>
                <div class="contentnum">1个</div>
              </div>
              <div class="content">
                <div class="contenttitle">磁盘</div>
                <div class="contentnum">高IO云硬盘100G</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.scene {
  background-color: #f5f7fa;
  padding-top: 90px;
  padding-bottom: 112px;
  .scenebottpm {
    margin-bottom: 40px !important;
  }
  .sceneinfo {
    border: 1px solid rgba(0, 0, 0, 0.25);
    padding: 20px;
    padding-bottom: 43px;
    width: 497px;
    box-sizing: border-box;
    .contentborder {
      border-left: 1px solid rgba(0, 0, 0, 0.25);
      border-right: 1px solid rgba(0, 0, 0, 0.25);
      padding: 0px 51px;
    }
    .content {
      display: inline-block;
      font-size: 14px;
      margin-right: 45px;
      .contenttitle {
        font-weight: 500;
        color: #616b80;
        line-height: 20px;
      }
      .contentnum {
        margin-top: 15px;
      }
    }
  }
  .scenetext {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #616b80;
    line-height: 20px;
    width: 426px;
  }
  .scenetable {
    margin-left: 196px;
    display: flex;
    .sceneimg {
      img {
        width: 792px;
      }
    }
    .scenecard {
      background-color: #fff;
      padding: 40px;
      width: 557px;
      box-sizing: border-box;
      margin-left: 121px;
      .scenetitle {
        font-size: 24px;
        font-weight: 500;
        line-height: 33px;
        margin-bottom: 20px;
      }
    }
  }
}

.where {
  margin-top: 140px;
  .wheretable {
    margin: auto;
    margin-top: 60px;
    width: 68%;
    .wherecard {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 108px;
      .whereimg {
        img {
          width: 287px;
        }
      }
      .whereinfo {
        width: 721px;
        .wheretitle {
          font-size: 24px;
          font-weight: 500;
          line-height: 33px;
        }
        .wheretext {
          margin-top: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #616b80;
          line-height: 20px;
          // display: flex;
        }
        .wherediv {
          vertical-align: top;
          width: 690px;
          display: inline-block;
        }
      }

      .trademark {
        margin-top: 41px;
        padding-top: 41px;
        border-top: 1px solid #c2c2c2;
        img {
          width: 135px;
          vertical-align: middle;
        }
        span {
          margin-left: 40px;
          vertical-align: middle;
          display: inline-block;
          width: 547px;
          font-size: 14px;
          font-weight: 500;
          color: #616b80;
          line-height: 20px;
        }
      }
    }
  }
}

.appeal {
  margin: auto;
  margin-top: 140px;
  width: 73%;
  .appealtable {
    padding: 40px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
    display: flex;

    .appealimg {
      margin-top: 73px;
      margin-left: 98px;
      img {
        width: 374px;
        height: 338px;
      }
    }
    .appealinfo {
      .appealtitle {
        font-size: 24px;
        font-weight: 500;
        line-height: 33px;
      }
      .tag {
        background-color: #e02020;
        color: #fff;
        font-size: 18px;
        border-radius: 4px;
        padding: 2px 9px;
        line-height: 25px;
        vertical-align: middel;
      }
      .appealtext {
        font-size: 14px;
        font-weight: 500;
        color: #616b80;
        line-height: 20px;
        margin: 18px 0px;
      }
      .appealtlist {
        margin-top: 18px;
        font-size: 14px;
        font-weight: 500;
        color: #616b80;
        line-height: 20px;
        .text {
          margin-bottom: 10px;
        }
      }

      .appeallisttwo {
        margin-top: 40px;
        .appeallisttwocard {
          text-align: center;
          display: inline-block;
          margin-right: 60px;
          .appealcardtitle {
            font-size: 14px;
            font-weight: 500;
            color: #616b80;
            line-height: 20px;
          }
          .appealcardtext {
            margin-top: 10px;
            font-size: 20px;
            font-weight: 500;
            color: #000000;
            line-height: 28px;
          }
        }
      }
    }
  }
  .appealine {
    margin-top: 60px;
    .appealinetitle {
      margin-bottom: 20px;
      .linetitle {
        font-size: 14px;
        font-weight: 500;
        color: #616b80;
        line-height: 20px;
      }
      .lineinfo {
        font-size: 14px;
        font-weight: 500;
        color: #e02020;
        line-height: 20px;
        margin-left: 10px;
      }
    }
  }
}
.title {
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
}

.title2 {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #616b80;
  line-height: 33px;
  margin-top: 20px;
}

.block {
  width: 10px;
  height: 10px;
  background: #2f7ffc;
  display: inline-block;
  margin-right: 15px;
  line-height: 10px;
}
</style>
